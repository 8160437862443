import React from 'react';
import useStore from '../../../customHooks/useStore';

function LanguageToggle() {
  const { language, setLanguage } = useStore((state) => ({
    language: state.language,
    setLanguage: state.setLanguage,
  }));

  console.log(language, 'language');

  console.log(language, 'lang');

  return (
    <div className='toggle'>
      <button
        className={`toggle-section ${language === 'en' && 'active'}`}
        onClick={() => setLanguage('en')}
      >
        <span>English</span>
      </button>
      <button
        className={`toggle-section ${language === 'es' && 'active'}`}
        onClick={() => setLanguage('es')}
      >
        <span>Español</span>
      </button>
    </div>
  );
}

export default LanguageToggle;
