import { create } from 'zustand';

const useStore = create((set) => ({
  language: window.Localize.getLanguage(),
  setLanguage: (language) => {
    window.Localize.setLanguage(language);
    set({ language: language });
  },
}));

export default useStore;
