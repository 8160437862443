import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../context';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import useFetch from '../../../customHooks/useFetch';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import LoginHeader from './LoginHeader';
const SignUp = () => {
  const history = useHistory();
  const context = useContext(Context);
  const [form, setForm] = useState({});
  const [checked, setChecked] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [res, callAPI] = useFetch({
    url: 'registration',
    method: 'POST',
    payload: form,
    responseFormat: 'TEXT',
  });

  const handleFullReplace = (value) => {
    const digitsOnly = value.replace(/[^\d]/g, ''); // Strip all non-digits from the phone number
    const lastTenDigits = digitsOnly.slice(-10); // Slice off everything but the last 10 digits
    const formattedPhoneNumber = lastTenDigits.replace(
      /(\d{3})(\d{3})(\d{4})/,
      '$1-$2-$3'
    );
    return formattedPhoneNumber;
  };

  const handleChangeInput = (element) => {
    const { name, value } = element;
    if (element.name === 'phone') {
      const currentVal = form['phone']?.length || 0;
      console.log(currentVal <= value.length);
      if (currentVal <= value.length || currentVal === 0) {
        if (currentVal === 0 && value >= 10) {
          setForm((prevState) => ({
            ...prevState,
            [name]: handleFullReplace(value),
          }));
        } else {
          setForm((prevState) => ({
            ...prevState,
            [name]: value
              .replace(/^(\d{3})$/g, '$1-')
              .replace(/^(\d{3}\-\d{3})$/g, '$1-'),
          }));
        }
      } else {
        setForm((prevState) => ({ ...prevState, [name]: value }));
      }
    } else {
      setForm((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const getAttributes = (name, state, handleChange) => {
    return {
      name: name,
      value: state[name] || '',
      onChange: (e) => handleChange(e.target),
    };
  };

  useEffect(() => {
    if (res.data) {
      localStorage.setItem('name', form.first);
      localStorage.setItem('initialVideo', false);
      context.login(res.data);
      history.push('/course/overview');
    }
  }, [res]);

  return false ? (
    <Redirect to={'/login'} />
  ) : (
    <div className='auth-wrapper'>
      <LoginHeader logedInOrSignUp='signUp' />
      <section>
        <div className='logo'>&nbsp;</div>
        <div className='login-wrap'>
          <div className='inner-wrap'>
            <h1>Sign Up</h1>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                callAPI();
              }}
            >
              <label>
                <p>First Name</p>
                <input
                  required
                  type='text'
                  {...getAttributes('first', form, handleChangeInput)}
                />
              </label>
              <label>
                <p>Last Name</p>
                <input
                  required
                  type='text'
                  {...getAttributes('last', form, handleChangeInput)}
                />
              </label>
              <label>
                <p>Email</p>
                <input
                  required
                  type='email'
                  {...getAttributes('email', form, handleChangeInput)}
                />
              </label>
              <label>
                <p>Phone</p>
                <input
                  maxLength={12}
                  type='tel'
                  pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}'
                  {...getAttributes('phone', form, handleChangeInput)}
                />
                <div className='format'>Format: 123-456-7890</div>
                <label className='phoneLabel'>
                  <input
                    type='radio'
                    onClick={() => setChecked(!checked)}
                    checked={checked}
                  />
                  <span>
                    Check this box to opt-in for program reminders via text
                    message. You may receive up to 6 messages. Message and data
                    rates may apply. You can reply STOP to unsubscribe.
                  </span>
                </label>
              </label>
              <label>
                <p>Username</p>

                <input
                  required
                  type='text'
                  pattern='\S+'
                  title='Whitespace is not allowed!'
                  {...getAttributes('username', form, handleChangeInput)}
                />
              </label>
              <label>
                <p>Password</p>
                <input
                  type='text'
                  required
                  pattern='\S+'
                  title='Whitespace is not allowed!'
                  {...getAttributes('password', form, handleChangeInput)}
                />
              </label>
              <label>
                <p>Code</p>
                <input
                  required
                  type='text'
                  pattern='\S+'
                  title='Whitespace is not allowed!'
                  {...getAttributes('code', form, handleChangeInput)}
                />
                <p className='grey'>
                  Your password and code is provided by programming staff
                </p>
              </label>
              {res.error && <p className='error'>{res.error}</p>}
              <div className='button-wrap'>
                <button className='button' type='submit'>
                  Sign Up
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignUp;
