import './profile.scss';
import VideoPlayer from '../../shared/video-player/video-player';
import { Context } from '../../../context';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ResetPassword from '../login/ResetPassword';
import React, { useContext, useEffect, useState } from 'react';
import './profile.scss';
import allBadges from '../../shared/badges/allBadges';
import getPDF from '../../../assets/helpers/getPDF';
import PDF from '../../../assets/handouts/EZCertificate2.pdf';
import logoutFetch from '../../../assets/helpers/logoutFetch';
import AddHome from '../../../assets/handouts/addToHome_ezParent.pdf';
function ProfilePage() {
  const context = useContext(Context);
  const [completedBadges, setCompletedBadges] = useState([]);
  const [completedCert, setCompletedCert] = useState();
  const [pdf, setPdf] = useState(false);

  const handleBadgeGet = async () => {
    try {
      let data = await fetch(process.env.REACT_APP_API_URL + `/api/badge`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (data.ok) {
        let response = await data.json();
        setCompletedBadges(response);
      } else {
        const errorMessage = await data.text();
        throw new Error(errorMessage);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    context.setSidebarModuleOpen(0);
    handleBadgeGet();
  }, []);

  useEffect(() => {
    if (completedBadges) {
      const haveAllBadges = allBadges
        .filter((item) => item.type === 'module')
        .every((item) => completedBadges.includes(item.badgeName));

      setCompletedCert(haveAllBadges);
      if (haveAllBadges) {
        getPDF().then((resp) => setPdf(resp));
      }
    }
  }, [completedBadges]);

  return (
    <section className='slide-wrap' id={'fade'}>
      <h1 className='title'>Profile</h1>
      <div className='video-parent'>
        <div className='video-wrap'>
          <VideoPlayer
            source={{
              en: 749604203,
              es: 749604203,
            }}
          />
        </div>
      </div>
      <div className='buttonWrap'>
        <div className='supportWrapper'>
          <Link to={'/reset-password'} className='  supportButton'>
            <p>Reset Password</p>
          </Link>
        </div>
        <a
          href={AddHome}
          target={'_blank'}
          className={'btn-1 add-home'}
          rel='noreferrer'
        >
          Add to Homescreen
        </a>
        <Link
          to={'/login'}
          className='btn-1 log-out'
          onClick={() => {
            logoutFetch();
            context.logout();
          }}
        >
          Log Out
        </Link>
        <button
          onClick={() => (completedCert && pdf ? window.open(pdf) : '')}
          className={`btn-1 download-cert ${!completedCert && 'not-complete'}`}
          rel='noreferrer'
        >
          Download Certificate
        </button>
      </div>
    </section>
  );
}

export default ProfilePage;
