import allHandouts from '../../components/shared/handouts/allHandouts';
const mod1 = [
  { target: allHandouts.handout11, name: 'Summary of Important Points' },
];
const mod2 = [
  {
    target: allHandouts.handout21,
    name: 'Examples of Ways to Give Praise and Encouragement to Children',
  },
  {
    target: allHandouts.handout22,
    name: `Examples of Praise & Encouragement to Give to Your Child’s
Teacher, Caregiver, or Family Member`,
  },
  {
    target: allHandouts.handout23,
    name: 'How to Begin & End an Effective Reward Program',
  },
  { target: allHandouts.handout24, name: 'Summary of Important Points' },
];
const mod3 = [
  {
    target: allHandouts.handout31,
    name: 'Say What You Mean & Mean What You Say',
  },
  {
    target: allHandouts.handout32,
    name: 'Summary of Important Points',
  },
];
const mod4 = [
  {
    target: allHandouts.handout41,
    name: '5 Common Questions About Ignoring',
  },
  {
    target: allHandouts.handout42,
    name: 'Summary of Important Points',
  },
];
const mod5 = [
  {
    target: allHandouts.handout51,
    name: '8 Keys to Effective Discipline',
  },
  {
    target: allHandouts.handout52,
    name: 'Steps to an Effective Time-out',
  },
  {
    target: allHandouts.handout53,
    name: 'Summary of Important Points',
  },
];
const mod6 = [
  {
    target: allHandouts.handout61,
    name: 'Take 5 Breathing Exercise',
  },
  {
    target: allHandouts.handout62,
    name: 'Recipe for Making Mind Jars',
  },
  {
    target: allHandouts.handout63,
    name: 'Summary of Important Points',
  },
];

export { mod1, mod2, mod3, mod4, mod5, mod6 };
